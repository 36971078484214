// style.scss
// Common mixins and variables
@import '@tui/ui-library/scss/variables';
@import '@tui/ui-library/scss/mixins';

// A common reset file to normalize some user-agent styles across browsers.
// Not working properly at the moment as it conflicts with some of our custom styles
// @import "@tui/ui-library/scss/reset";
// The TUI theme file, that will give us all the theme variables needed.
@import '@tui/ui-library/scss/themes/tui-light';
// Fontface definitions so we can use the theme fonts as well.
@import '@tui/ui-library/scss/themes/tui-light/fonts';

@import '@tui/ui-library/scss/lib/accordion';
@import '@tui/ui-library/scss/lib/alert';
@import '@tui/ui-library/scss/lib/badge';
@import '@tui/ui-library/scss/lib/button';
@import '@tui/ui-library/scss/lib/dialog';
@import '@tui/ui-library/scss/lib/dialog/modal';
@import '@tui/ui-library/scss/lib/divider';
@import '@tui/ui-library/scss/lib/icon';
@import '@tui/ui-library/scss/lib/icon-button';
@import '@tui/ui-library/scss/lib/input';
@import '@tui/ui-library/scss/lib/input/text';
@import '@tui/ui-library/scss/lib/input/select';
@import '@tui/ui-library/scss/lib/input/radio';
@import '@tui/ui-library/scss/lib/link';
@import '@tui/ui-library/scss/lib/tab';
@import '@tui/ui-library/scss/lib/typography';
@import '@tui/ui-library/scss/lib/accordion';

$tui-theme: 'tui-light';

// Set mask image
@mixin icon-mask-image($name) {
  --icon-url: url('https://static.tui.com/assets/v2/icons/#{$tui-theme}/#{$name}.svg');
  -webkit-mask-image: var(--icon-url);
  mask-image: var(--icon-url);
}

span .icon {
  &.aircraft {
    @include icon-mask-image('aircraft');
  }

  &.aircraft-left {
    @include icon-mask-image('aircraft');
    transform: rotate(-90deg);
  }

  &.aircraft-right {
    @include icon-mask-image('aircraft');
    transform: rotate(90deg);
  }

  &.aircraft-up {
    @include icon-mask-image('aircraft-up');
  }

  &.aircraft-down {
    @include icon-mask-image('aircraft-down');
  }

  &.arrow-left-circled {
    @include icon-mask-image('arrow-left-circled');
  }

  &.seat {
    @include icon-mask-image('seat');
  }

  &.pencil {
    @include icon-mask-image('pencil');
  }

  &.letter-i-circled-filled {
    @include icon-mask-image('letter-i-circled-filled');
  }
  
  &.checkmark-circled-filled {
    @include icon-mask-image('checkmark-circled-filled');
  }

  &.letter-i-circled {
    @include icon-mask-image('letter-i-circled');
  }

  &.exclamation-mark-circled-filled {
    @include icon-mask-image('exclamation-mark-circled-filled');
  }

  &.chevron-down {
    @include icon-mask-image('chevron-down');
  }

  &.chevron-up {
    @include icon-mask-image('chevron-up');
  }

  &.circle {
    @include icon-mask-image('circle');
  }

  &.cross {
    @include icon-mask-image('cross');
  }

  &.seat {
    @include icon-mask-image('seat');
  }
  &.warning-filled {
    @include icon-mask-image('warning-filled');
  }

  &.ticket {
    @include icon-mask-image('ticket');
  }

  &.telephone-receiver {
    @include icon-mask-image('telephone-receiver');
  }

  &.pricetag {
    @include icon-mask-image('pricetag');
  }

  &.link-external {
    @include icon-mask-image('link-external');
  }

  &.location-pin {
    @include icon-mask-image('location-pin');
  }

  &.letter-at {
    @include icon-mask-image('letter-at');
  }

  &.telephone-receiver {
    @include icon-mask-image('telephone-receiver');
  }

  &.exclamation-mark-circled {
    @include icon-mask-image('exclamation-mark-circled');
  }
}

.tab:focus:after {
  display: none;
}
